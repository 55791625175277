import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { finalize, Observable, Subject, takeUntil } from "rxjs";
import { NotificationService } from "../../../../core/services/notification/notification.service";
import { IUserNotification } from "../../../../core/models/notification/user-notification.model";
import { ENotificationRelatedObjectType } from "../../../../core/enums/notification-related-object-type.enum";
import { Store } from "@ngrx/store";
import { UnreadNotificationState } from "../../../../core/ngrx/reducers/unread-notification.reducer";
import { isPlatformBrowser } from "@angular/common";
import { selectUnreadNotifications } from "../../../../core/ngrx/selectors/unread-notification.selectors";
import { setUnreadNotification } from "../../../../core/ngrx/actions/unread-notification.action";
import { Router } from "@angular/router";
import { ENotificationTemplateKey } from "../../../../core/enums/notification-template-key.enum";
import { IGenericKeyValue } from "../../../../core/models/exam/general.model";

@Component({
  selector: 'app-shared-notification-list',
  templateUrl: './shared-notification-list.component.html',
  styleUrl: './shared-notification-list.component.scss'
})
export class SharedNotificationListComponent implements OnInit, OnDestroy {
  @Input() scrollContainer = '#main-container';
  unreadNotification$: Observable<number | null>;
  unreadNotification?: number | null;
  isBrowser: boolean;
  notEmpty = true;
  notScroll = true;
  isLoading = false;
  currentPage = 1;
  notifications: (IUserNotification & { iconKey?: string })[] = [];
  destroyed$ = new Subject();

  constructor(
    private notificationService: NotificationService,
    private store: Store<UnreadNotificationState>,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.unreadNotification$ = store.select(selectUnreadNotifications);
  }

  ngOnInit() {
    if (this.isBrowser) {

      this.unreadNotification$.pipe(takeUntil(this.destroyed$))
        .subscribe(unread => {
          this.unreadNotification = unread;

          if (unread === 0) {
            // Mark all notification items as read
            for (let i = 0; i < this.notifications.length; i++) {
              this.notifications[i].isRead = true;
            }
          }
        });

    }
    this.loadUnreadNotifications();
    this.getList();
  }

  loadUnreadNotifications() {
    this.notificationService.getUnreadNotificationCount()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        this.store.dispatch(setUnreadNotification(r.data));
      });
  }

  getList() {
    this.isLoading = true;

    this.notificationService.getNotifications(`?page=${this.currentPage}&size=30`)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.isLoading = false))
      .subscribe(r => {
        if (r.data.result.length === 0) {
          this.notEmpty = false;
        } else {

          const data = r.data.result as IUserNotification[];
          const dataList = data.map(n => {
            const notificationType = n.notification?.relatedObjectType;
            let iconKey = 'pin';
            if (notificationType) {
              switch (notificationType) {
                case ENotificationRelatedObjectType.Video:
                  iconKey = 'videos';
                  break;
                case ENotificationRelatedObjectType.Blog:
                  iconKey = 'blog';
                  break;
                case ENotificationRelatedObjectType.LiveStream:
                  iconKey = 'stream';
                  break;
                case ENotificationRelatedObjectType.Comment:
                  iconKey = 'comment';
                  break;
                case ENotificationRelatedObjectType.Document:
                  iconKey = 'document-thin';
                  break;
              }
            }


            return {
              ...n,
              iconKey,
            }
          }) as (IUserNotification & { iconKey?: string })[];
          this.notifications.push(...dataList);

        }

        this.notScroll = true;
      });
  }

  goToNotificationDetail(n: IUserNotification, index: number) {
    if (!n.isRead) {
      this.notificationService.readNotifications({notificationIds: [n.id]})
        .subscribe(r => {
          this.notifications[index].isRead = true;

          if (this.unreadNotification) {
            this.store.dispatch(setUnreadNotification(this.unreadNotification - 1));
          }

        });
    }

    const notificationType = n.notification.relatedObjectType;
    const relatedId = n.notification.relatedObjectId;
    let queryParams: IGenericKeyValue = {};

    if (
      n.notification?.notificationTemplate?.key === ENotificationTemplateKey.VideoCommentReply ||
      n.notification?.notificationTemplate?.key === ENotificationTemplateKey.CommunityPostCommentReply
    ) {
      // Parent comment query parameter
      if (n.notification.extras?.parentCommentId) {
        queryParams['cid'] = n.notification.extras?.parentCommentId;
      }
      // Sub-comment query parameter
      if (n.notification.extras?.commentId) {
        queryParams['scid'] = n.notification.extras?.commentId;
      }
    }

    this.notificationService.routeNotification(notificationType, relatedId, queryParams);

  }

  onScroll() {
    if (this.notScroll && this.notEmpty) {
      this.currentPage += 1;
      this.notScroll = false;
      this.getList();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
